<template>
  <div class="SignUp" v-fixed>
    <div class="topbar">
      <img @click="$router.go(-1)" :src='require("@/assets/imgs/back.png")' style="width: .8rem;height: .6rem;    margin-left: .64rem;"/>
    </div>

    <!-- 表单 -->
    <div class="form">
      <div class="form-title">Sign Up!</div>
      <div class="form-switch">
        <div
          :class="[switchActive ? 'active sign-up' : 'sign-up']"
          @click="switchActive = true"
        >
          {{ $t("註冊") }}
        </div>
        <div
          :class="[switchActive ? 'fast-sign' : 'active fast-sign']"
          @click="switchActive = false"
        >
          {{ $t("快速註冊") }}
        </div>
      </div>
      <!-- 注册 -->
      <v-card v-if="switchActive" elevation="0" style="  background-color: #F0F6F9;">
        <v-form class="mt-5" ref="form" >
          <v-text-field
            dense
            :placeholder="$t('請輸入用戶名')"
            color="info"
            outlined
            clearable
            v-model="account"
            :rules="[rules.required, rules.account]"
            prepend-inner-icon="mdi-account"
            class="rounded-lg"
          ></v-text-field>
          <v-text-field
            dense
            color="info"
            prepend-inner-icon="mdi-lock"
            :placeholder="$t('請輸入密碼')"
            outlined
            clearable
            v-model="password1"
            :type="'password'"
            :rules="[rules.required, rules.counter, rules.password1]"
            class="rounded-lg"
          ></v-text-field>
          <v-text-field
            dense
            color="info"
            prepend-inner-icon="mdi-lock"
            :placeholder="$t('請再次輸入密碼')"
            outlined
            clearable
            v-model="password2"
            :type="'password'"
            :rules="[rules.required, rules.password2]"
            class="rounded-lg"
          ></v-text-field>
          <v-btn
            height="50"
            block
            class="rounded-lg mt-1 btn1"
            bottom
            @click="validate"
          >
            {{ $t("確定") }}
          </v-btn>
        </v-form>
      </v-card>
      <!-- 快速注册 -->
      <v-card v-else elevation="0" style="  background-color: #F0F6F9;">
        <v-form class="mt-5" ref="form">
          <v-text-field
            dense
            readonly
            :placeholder="$t('用戶名') + ':' + userID"
            background-color="#EEEBEB"
            color="info"
            outlined
            clearable
            v-model="quickaccount"
            class="rounded-lg"
          ></v-text-field>
          <v-text-field
            dense
            readonly
            color="info"
            background-color="#EEEBEB"
            :placeholder="$t('密碼') + ':' + randomPsword"
            outlined
            clearable
            v-model="quickpassword"
            class="rounded-lg"
          ></v-text-field>
          <v-btn
            height="50"
            block
            class="rounded-lg mt-1 btn1"
            bottom
            @click="fastvalidate"
          >
            {{ $t("確定") }}
          </v-btn>
        </v-form>
      </v-card>
    </div>

    <div v-if="!switchActive" class="hint">
      <div class="hint-content">
        <p class="ma-0 pa-0">{{ $t("為防止帳號丟失") }}</p>
        <h1 class="ma-0 pa-0">{{ $t("請截圖保存您的用戶名及密碼") }}</h1>
        <v-btn
            height="50"
            block
            
            class="rounded-lg mt-1 btn1 elevation-0"
            bottom
            @click="fastvalidate"
          >
            {{ $t("生成個人帳號憑證") }}
          </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n/index";
export default {
  data() {
    return {
      userID: "",
      randomPsword: "",
      switchActive: true,
      isshow: true,
      account: "",
      quickaccount: "",
      quickpassword: "",
      password1: "",
      password2: null,
      rules: {
        required: (value) => !!value || i18n.t("必填項"),
        counter: (value) =>
          (value && value.length >= 6 && value.length <= 20) ||
          i18n.t("密碼必須為6-20位英文+數字"),
        account: (value) => {
          const pattern = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
          return pattern.test(value) || i18n.t("用戶名英文+數字6-20位");
        },
        password1: (value) => {
          const pattern =
            /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))^.{6,20}$/;
          return pattern.test(value) || i18n.t("密碼格式英文+數字6-20位");
        },
        password2: (value) =>
          value === this.password1 || i18n.t("兩次密碼不一致"),
      },
    };
  },
  created() {
    const str1 = Math.random().toString(36).substr(2, 8);
    const str2 = Math.random().toString(36).substr(2, 8);
    this.userID = str1;
    this.randomPsword = str2;
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$post("/Api/Account/Register", {
          account: this.account,
          Password: this.password1,
          ConfirmPassword: this.password2,
          gameId: process.env.VUE_APP_API_BASE_GameId,
        }).then((res) => {
          this.$toast(i18n.t("註冊成功"));
          this.$router.push({
            name: "AccountCode",
            params: {
              account: this.account,
              key: res.data.key,
            },
          });
        });
      }
    },
    // 快速注册
    fastvalidate() {
      this.$post("/Api/Account/Register", {
        account: this.userID,
        password: this.randomPsword,
        confirmPassword: this.randomPsword,
        gameId: process.env.VUE_APP_API_BASE_GameId,
      }).then((res) => {
        this.$toast(i18n.t("註冊成功"));
        this.$router.push({
          name: "AccountCode",
          params: {
            account: this.userID,
            key: res.data.key,
          },
        });
      });
    },
  },
  directives: {
    fixed: {
      // inserted 被绑定元素插入父节点时调用
      inserted() {
        let scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        document.body.style.cssText +=
          "position:fixed;width:100%;top:-" + scrollTop + "px;";
      },
      // unbind 指令与元素解绑时调用
      unbind() {
        let body = document.body;
        body.style.position = "";
        let top = body.style.top;
        document.body.scrollTop = document.documentElement.scrollTop =
          -parseInt(top);
        body.style.top = "";
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.SignUp {
  padding-top: 46px;
  .topbar {
    display: flex;
    align-items: center;
    background-color: #F0F6F9;
    text-align: left;
    height: 46px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    .v-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
    }
    .head-title {
      position: absolute;
      line-height: 1;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      left: 50%;
      font-size: 18px;
      color: #485573;
    }
  }
  .form {
    z-index: 100;
    padding: 0 24px;
    .form-title {
      margin-top: 20px;
      margin-bottom: 42px;
      padding: 0 3px;
      text-align: left;
      font-weight: 400;
font-size: 28px;
line-height: 39px;
color: #485573;
    }
    .form-switch {
      display: flex;
      .sign-up {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: #c9c9c9;
        width: 30%;
        text-align: left;
        padding: 8px 5px;
      }
      .fast-sign {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: #c9c9c9;
        width: 70%;
        text-align: left;
        padding: 8px 5px;
      }
      .active {
        font-size: 25px;
        font-weight: 700;
        color: #00C7DB;
        border-bottom: 1px solid #f84279;
      }
    }
    .v-card {
      position: absolute;
      padding: 0 24px 30px;
      left: 0;
      right: 0;
      border-radius: 0px 0px 40px 40px;
      box-shadow: 0px 10px 25px #dc2159;
      background-color: #fff;
      z-index: 100;
    }

    .btn1 {
      background: linear-gradient(91.69deg, #f84077 16.39%, #f95e93 139.39%);
      box-shadow: 0px 15px 20px rgba(245, 86, 136, 0.2);
      color: #fff;
      font-size: 16px;
    }
  }
  .hint {
    position: absolute;
    top: 40%;
    bottom: 0;
    left: 0;
    right: 0;

    .hint-content {
      position: absolute;
      width: 100%;
      top: 60%;
      transform: translateY(-50%);
      padding: 20px 24px;
      p {
        font-size: 18px;
line-height: 20px;
color: #485573;
      }
      h1 {
        margin: 5px 0 !important;
        padding: 0 30px !important;
        font-size: 22px;
line-height: 25px;
color: #485573;
      }
      .v-btn {
        border: 1px solid #F84E84;
border-radius: 10px;
font-size: 0.3rem;
    line-height: 15px;
    text-align: center;
    color: #F84279;
      }
    }
  }
}
</style>
